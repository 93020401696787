import Menu from "../../popups/menus/Menu";
import { BookmarkBook, ClipboardCheck, QuoteMessage, Cloud, Community, Leaderboard, StatsUpSquare } from "iconoir-react";

function CreateMenu({ triggerClass }) {
    return (
        <Menu
            triggerElement={
                <span className={triggerClass}>+</span>
            }
            position="bottom right"
            items={[
                {
                    label: 'Ny läxa',
                    icon: <BookmarkBook />,
                    onClick: () => { window.location.href = '/create/set' }
                },
                {
                    label: 'Nytt läxförhör',
                    icon: <ClipboardCheck />,
                    onClick: () => { window.location.href = '/create/test' }
                },
                {
                    label: 'Ny tankespegel',
                    icon: <QuoteMessage />,
                    onClick: () => { window.location.href = '/create/test' }
                },
                {
                    label: 'Nytt tankemoln',
                    icon: <Cloud />,
                    onClick: () => { window.location.href = '/create/test' }
                },
                {
                    label: 'Nytt quiz',
                    icon: <Leaderboard />,
                    onClick: () => { window.location.href = '/create/test' }
                },
                {
                    label: 'Ny omröstning',
                    icon: <StatsUpSquare />,
                    onClick: () => { window.location.href = '/create/test' }
                },
                {
                    label: 'Ny klass',
                    icon: <Community />,
                    onClick: () => { window.location.href = '/create/test' }
                },
            ]}
        />
    )
}

export default CreateMenu;