import styles from './CreateTestForm.module.scss';
import NormalInput from '../../../inputs/NormalInput';
import { useCallback, useState } from 'react';
import TestQuestion from './TestQuestion';
import SecondaryButton from '../../../buttons/SecondaryButton';
import { Plus } from 'iconoir-react';

function CreateTestForm({ onChange, defaultName, defaultQuestions }) {
    const [ testName, setTestName ] = useState(defaultName || '');
    const [ questions, setQuestions ] = useState(defaultQuestions || [{}]);

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setTestName(newName);
        onChange(newName, questions);
    };

    // ------------------
    //  Handle questions
    // ------------------

    function handleAddQuestion() {
        setQuestions(current => [...current, {}]);
    }

    function handleRemoveQuestion(index) {
        setQuestions(current => current.filter((_, i) => i !== index));
    }

    const handleQuestionChange = useCallback((index, question) => {
        setQuestions(current => {
            const newQuestions = [...current];
            newQuestions[index] = question;
            return newQuestions;
        });

        onChange(testName, questions);
    }, [setQuestions, onChange, testName, questions]);

    return (
        <div className={styles.form}>
            <div className={styles.inputWrapper}>
                <NormalInput
                    label="Namn"
                    title="Skriv namnet på läxförhöret"
                    placeholder="Skriv namnet på läxförhöret"
                    type="text"
                    onChange={handleNameChange}
                    value={testName}
                />
            </div>
            <div className={styles.questions}>
                <h3>Frågor</h3>
                <div className={styles.questionWrapper}>
                    {questions.map((question, index) => (
                        <TestQuestion
                            key={index}
                            questionType={question.questionType}
                            onRemove={() => handleRemoveQuestion(index)}
                            onChange={value => handleQuestionChange(index, value)}
                        />
                    ))}
                </div>
                <div className={styles.buttonWrapper}>
                    <SecondaryButton onClick={handleAddQuestion}>
                        <Plus />
                        Lägg till fråga
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
}

export default CreateTestForm;