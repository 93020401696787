import styles from './TestQuestion.module.scss';
import { Trash, Copy, Plus, Xmark } from 'iconoir-react';
import NormalInput from '../../../inputs/NormalInput';
import { useEffect, useRef, useState } from 'react';
import Checkbox from '../../../inputs/Checkbox';

function TestQuestion({ defaultTitle = '', defaultType = 'exact', defaultOpen = false, defaultCaseSensitive = false, defaultAlternatives = [], defaultAnswer = [''], onRemove, onChange }) {
    const [ title, setTitle ] = useState(defaultTitle);
    const [ type, setType ] = useState(defaultType);
    const [ open, setOpen ] = useState(defaultOpen);
    const [ caseSensitive, setCaseSensitive ] = useState(defaultCaseSensitive);
    const [ answer, setAnswer ] = useState(defaultAnswer);
    const [ alternatives, setAlternatives ] = useState(defaultAlternatives);
    const [ correctAlternatives, setCorrectAlternatives ] = useState([]);

    // ---------------
    //  Value updates
    // ---------------

    const previousState = useRef({ title, type, open, case_sensitive: caseSensitive, alternatives, answer, correct_alternatives: correctAlternatives });

    useEffect(() => {
        const newValue = {
            title: title,
            type: type,
            open: open,
            case_sensitive: caseSensitive,
            alternatives: alternatives,
            answer: answer,
            correct_alternatives: correctAlternatives
        };
    
        // Check if any of the values have changed
        if (
            newValue.title !== previousState.current.title ||
            newValue.type !== previousState.current.type ||
            newValue.open !== previousState.current.open ||
            newValue.case_sensitive !== previousState.current.case_sensitive ||
            newValue.alternatives !== previousState.current.alternatives ||
            newValue.answer.join(',') !== previousState.current.answer.join(',') ||
            newValue.correct_alternatives.join(',') !== previousState.current.correct_alternatives.join(',')
        ) {
            onChange(newValue);
            previousState.current = newValue; // Update ref for next comparison
        }
    }, [title, open, caseSensitive, type, alternatives, answer, correctAlternatives, onChange]);

    // --------------
    //  Alternatives
    // --------------

    function addAlternative() {
        setAlternatives(current => {
            return [...current, ''];
        });
    }

    function removeAlternative(index) {
        if (alternatives.length === 1) return;
        setAlternatives(current => current.filter((_, i) => i !== index));
    }

    // -------
    //  Types
    // -------

    useEffect(() => {
        switch (type) {
            case 'exact':
                setAnswer(['']);
                setAlternatives([]);
                break;

            case 'open':
                setAnswer([]);
                setAlternatives([]);
                break;

            case 'multiple-choice':
                setAnswer([]);
                setAlternatives(['']);
                setCorrectAlternatives([0]);
                break;

            case 'checkboxes':
                setAnswer([]);
                setAlternatives(['']);
                break;

            default:
                break;
        }
    }, [type]);

    // ----------------
    //  Answer content
    // ----------------

    const answerContent = {
        'exact': <>
            { answer.map((answer, index) => {
                return (
                    <span className={styles.answerInputRemovable} key={index}>
                        <span className={styles.inputWrapper}>
                            <NormalInput 
                                placeholder="Skriv rätt svar" 
                                type="text" 
                                value={answer}
                                onChange={e => { setAnswer(current => {
                                    const newAnswer = [...current];
                                    newAnswer[index] = e.target.value;
                                    return newAnswer;
                                }) }}
                            />
                        </span>
                        <Xmark onClick={() => short_removeAnswer(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={short_addAnswer}>
                <Plus />
                Lägg till rätt svar
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setCaseSensitive(current => { return !current }) }}>Känslig för gemener/versaler</Checkbox>
            </div>
        </>,
        'open': <><textarea className={styles.textarea} placeholder='Öppet textsvar' disabled /></>,
        'multiple-choice': <>
            { alternatives.map((_, index) => {
                return (
                    <span className={styles.radioInputRemovable} key={index}>
                        <span className={styles.radioWrapper}>
                            <div 
                                className={`${styles.radio}  ${correctAlternatives.includes(index) ? styles.selected : ''}`} 
                                onClick={() => { setCorrectAlternatives(() => { return [index] }) }}
                            ></div>
                            <input 
                                type="text" 
                                placeholder='Skriv alternativtext' 
                                value={alternatives[index]} 
                                onChange={e => { setAlternatives(current => {
                                    const newAlternatives = [...current];
                                    newAlternatives[index] = e.target.value;
                                    return newAlternatives;
                                });}}
                            />
                        </span>
                        <Xmark onClick={() => removeAlternative(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={addAlternative}>
                <Plus />
                Lägg till alternativ
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setOpen(current => { return !current }) }}>Öppet svar</Checkbox>
            </div>
        </>,
        'checkboxes': <>
            { alternatives.map((_, index) => {
                return (
                    <span className={styles.checkboxInputRemovable} key={index}>
                        <span className={styles.checkboxWrapper}>
                            <div 
                                className={`${styles.checkbox}  ${correctAlternatives.includes(index) ? styles.selected : ''}`} 
                                onClick={() => { setCorrectAlternatives(current => {
                                    const newAlternatives = [...current];
                                    if (newAlternatives.includes(index)) {
                                        newAlternatives.splice(newAlternatives.indexOf(index), 1);
                                    } else {
                                        newAlternatives.push(index);
                                    }

                                    return newAlternatives;
                                }) }}
                            ></div>
                            <input 
                                type="text" 
                                placeholder='Skriv alternativtext' 
                                value={alternatives[index]} 
                                onChange={e => { setAlternatives(current => {
                                    const newAlternatives = [...current];
                                    newAlternatives[index] = e.target.value;
                                    return newAlternatives;
                                });}}
                            />
                        </span>
                        <Xmark onClick={() => removeAlternative(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={addAlternative}>
                <Plus />
                Lägg till alternativ
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setOpen(current => { return !current }) }}>Öppet svar</Checkbox>
            </div>
        </>
    }

    // ------------
    //  Short text
    // ------------

    function short_addAnswer() {
        setAnswer(current => {
            return [...current, ''];
        })
    }

    function short_removeAnswer(index) {
        if (answer.length === 1) return;
        setAnswer(current => current.filter((_, i) => i !== index));
    }

    // --------------------------------
    //  Multiple-choice and Checkboxes
    // --------------------------------

    // Make sure correctAlternatives is in range of alternatives
    useEffect(() => {
        if (type === 'multiple-choice') {
            setCorrectAlternatives((prevCorrectAlternatives) => {
                if (prevCorrectAlternatives.length === 0 || 
                    prevCorrectAlternatives[0] >= alternatives.length) {
                    return [0];
                }
                return prevCorrectAlternatives;
            });
        } else if (type === 'checkboxes') {
            setCorrectAlternatives((prevCorrectAlternatives) => {
                const newAlternatives = [...prevCorrectAlternatives];

                newAlternatives.forEach((alternative, index) => {
                    if (alternative >= alternatives.length) {
                        newAlternatives.splice(index, 1);
                    }
                });

                return newAlternatives;
            });
        }
    }, [alternatives, type]);

    // -----------
    //  Component
    // -----------

    return (
        <div className={styles.question}>
            <div className={styles.main}>
                <div className={styles.title}>
                    <input type="text" className={styles.titleInput} placeholder='Skriv fråga' value={title} onChange={e => { setTitle(e.target.value) }} />
                    <select className={styles.typeSelect} defaultValue={defaultType} onChange={e => { setType(e.target.value) }}>
                        <option value="exact">Exakt textsvar</option>
                        <option value="open">Öppet textsvar</option>
                        <option value="multiple-choice">Flerval</option>
                        <option value="checkboxes">Kryssrutor</option>
                    </select>
                </div>
                <div className={styles.answerContent}>
                    {answerContent[type]}
                </div>
            </div>
            <div className={styles.aside}>
                <Trash onClick={onRemove} />
                <Copy />
            </div>
        </div>
    );
}

export default TestQuestion;