import styles from './CreateTest.module.scss';
import Navbar from "../../../components/navbars/Navbar";
import Auth from "../../../components/auth/Auth";
import ContainerSm from '../../../containers/ContainerSm';
import Section from '../../../containers/Section';
import CreateTestForm from '../../../components/pages/create/tests/CreateTestForm';
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ErrorMessage from "../../../components/popups/message/ErrorMessage";
import { useState } from 'react';
import axios from 'axios';

function CreateTest() {
    const [ name, setName ] = useState('');
    const [ questions, setQuestions ] = useState([]);
    const [ error, setError ] = useState('');

    function handleUpdate(nameValue, questionsValue) {
        setName(nameValue);
        setQuestions(questionsValue);
    }

    function handleSubmit() {
        console.log(name, questions);
        
        // Call the API and create the test
        axios.post('https://api.theodorlundin.se/studian/test/manage/create', {
            name: name,
            questions: questions
        }, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/dashboard';
        }).catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Skapa läxförhör</h2>
                            <PrimaryButton onClick={handleSubmit}>Skapa</PrimaryButton>
                        </div>
                        { error && <ErrorMessage message={error} /> }
                        <CreateTestForm onChange={handleUpdate} />
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default CreateTest;